.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.email-list-container {
  display: flex;
  gap: 0.4rem;
  margin: 10px 0;
}

.multi-selector-container {
  max-width: 600px;
  margin-bottom: 10px;
  display: flex;
  gap: 0.5rem;
}

.buttons-container {
  display: flex;
  gap: 0.5rem;
  place-items: center;
  margin-bottom: 10px;
}

.editor-container {
  display: flex;
  gap: 0.5rem;
}

.right-side {
  width: 30%;
}

.save-template {
  padding: 10px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 0.5rem;
}
