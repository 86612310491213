.attachment {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: 2px solid rgb(170, 170, 170);
  border-radius: 5px;
  cursor: pointer;
  padding: 0.2rem;
}

.attachment:hover {
  opacity: 0.5;
}

.attachment-container {
  display: flex;
  place-items: center;
  gap: 1rem;
}

.download-json {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: 2px solid rgb(170, 170, 170);
  border-radius: 5px;
  cursor: pointer;
  padding: 0.2rem;

  display: flex;
  place-items: center;
  justify-content: center;
  font-size: 10px;
  text-align: center;
}
